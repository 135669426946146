import { useState } from "react";
import axios from "axios";
import VerifyButton from "@passbase/button/react";
import { useNavigate } from "react-router-dom";

export default function Form() {
  const navigate = useNavigate();
  const [formData, setformData] = useState({
    fullName: "",
    emailId: "",
    phoneNumber: "",
  });

  const { fullName, emailId, phoneNumber } = formData;

  async function addUserToDb(identityAccessKey, e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const response = await axios.post(
      "https://us-central1-fraxso-v2.cloudfunctions.net/app/user",
      {
        fullName,
        emailId,
        phoneNumber,
        identityAccessKey,
      }
    );

    console.log(response);
  }

  const handleChange = (e) => {
    setformData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // console.log(formData.emailId.toString());

  return (
    <div className="w-full max-w-xs">
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 shadow-blue-300">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Full Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Please enter your full name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email ID
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Please enter your emailId"
            name="emailId"
            value={formData.emailId}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Phone Number
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Please enter your Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>
        <div className="flex items-center justify-center">
          <VerifyButton
            prefillAttributes={{
              // TO-DO: Add your own prefill attributes here
              // email: `${formData.emailId.toString()}`,
              country: "in",
            }}
            apiKey={
              "EZ3GeyX91xZotaufvJlpQiX45FUJv8PDAM4HWN32dj4HJHYF0beIo35i4pkQUPM2"
            }
            onFinish={(identityAccessKey) => {
              navigate("/dashboard");
            }}
            onSubmitted={(identityAccessKey) => {
              console.log("OnSubmitted Triggered");
              addUserToDb(identityAccessKey);
              alert("Saved User Successfully");
            }}
            onError={(errorCode) => {
              console.log(errorCode);
            }}
            onStart={(identityAccessKey) => {}}
          />
        </div>
      </form>
    </div>
  );
}
